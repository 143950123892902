import { useState } from "react";
import Profile from "./Profile";
import "./App.css";
import { WagmiConfig, createConfig } from 'wagmi'
import { createPublicClient, http } from 'viem'
import { bsc } from 'wagmi/chains'
import { InjectedConnector } from "wagmi/connectors/injected"
import { SafeConnector } from 'wagmi/connectors/safe'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
// import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";


const config = createConfig({
  autoConnect: true,
  connectors: [
    new InjectedConnector({
      chains: [bsc],
      options: {
        shimDisconnect: false,
      },
    }),
    new SafeConnector({
      chains: [bsc],
      options: {
        allowedDomains: [/gnosis-safe.io$/, /app.safe.global$/],
        debug: false,
      },
    }),
    new WalletConnectConnector({
      options: {
        projectId: '56eda5882ef0330cdf02a68d6fb549cc',
      },
    }),
  ],
  publicClient: createPublicClient({
    chain: bsc,
    transport: http()
  }),
})

const App = () => {
  const [isWalletConnected, setIsWalletConnected] = useState(false);


  const handleChild = (status) => {
    console.log(status,isWalletConnected);
    status ? setIsWalletConnected(true) : setIsWalletConnected(false);
  };

  return (
    <div className="App">
      <h1>Wagmi test</h1>
      <WagmiConfig config={config}>
        <Profile handleChild={handleChild} />
      </WagmiConfig>
    </div>
  );
};

export default App;
